import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  DeviceType,
  DiBookEpubReader,
  DiBookReader,
  IAdditionalContent,
  IIndexData,
  OnFluidTextSourceRequestedResult,
  OnPdfPageSourceRequestedResult
} from '@rocketcodeit/dibook-reader';
import '@rocketcodeit/dibook-reader/dist/dibook-reader.cjs.development.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Linking } from 'react-native';

export interface DiBookReaderWrapperProps {
  onBackButtonClick: () => void;
  onCurrentPageChange: (page: string) => void;
  onViewModeChanged?: (mode: 'PDF' | 'FLUID') => void;
  bookId: string;
  totalPages: number;
  index: IIndexData[];
  getAnnotations: (page: string) => Promise<string>;
  onAnnotationChange?: (page: string, annotations?: string) => void;
  additionalContents?: IAdditionalContent[];
  onPdfPageSourceRequested: (page: string) => Promise<OnPdfPageSourceRequestedResult>,
  onFluidTextSourceRequested: (page: string) => Promise<OnFluidTextSourceRequestedResult>,
  epubUrl?: string
}

function Index(props: DiBookReaderWrapperProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState<string>('');
  const [totalPages] = useState<number>(props.totalPages);
  const [index] = useState<IIndexData[]>(props.index);
  const [epubUrl] = useState<string | undefined>(props.epubUrl);

  const [currentEpubLocation, setCurrentEpubLocation] = useState<string | number | null>(null);
  const [epubInitialConfigLoaded, setEpubInitialConfigLoaded] = useState<boolean>(false);

  useEffect(() => {

    if (!currentPage) {

      AsyncStorage.getItem(props.bookId).then(page => {
        if (page)
          setCurrentPage(page);
        else {
          const firstChapter = index[0];
          setCurrentPage(firstChapter?.startPage ?? "1");
        }
      });
    }

    AsyncStorage.getItem(props.bookId + '_epub').then(location => {
      if (location) {
        setCurrentEpubLocation(location);
      }
    })
    .finally(() => {
        setEpubInitialConfigLoaded(true);
    })

  }, [])

  useEffect(() => {
    currentPage && props.onCurrentPageChange(currentPage);
  }, [currentPage]);

  return (
    <>
      {((currentPage && index.length) || epubUrl) &&
        <>{(epubUrl && epubInitialConfigLoaded) ?
          <DiBookEpubReader
            onLocationChanged={async (location) => {
              if(location) {
                setCurrentEpubLocation(location);
                await AsyncStorage.setItem(props.bookId + '_epub', location.toString());
              }
            }}
            location={currentEpubLocation}
            bookIndexStyle={{ backgroundColor: '#fff', textColor: '#000' }}
            headerStyle={{ backgroundColor: '#cc0000', textColor: '#fff' }}
            onBackButtonClicked={props.onBackButtonClick}
            epubUrl={epubUrl} /> :
          <DiBookReader
            onBackButtonClicked={props.onBackButtonClick}
            totalPages={totalPages}
            contentIndex={index}
            currentPage={currentPage}
            additionalContent={props.additionalContents}
            onCurrentPageChanged={(p: string, m: 'PDF' | 'FLUID') => {
              if (m === 'PDF') {
                setCurrentPage(p);
              }
            }}
            onViewModeChanged={(m: 'PDF' | 'FLUID', p: string) => {
              if (m === 'PDF') {
                setCurrentPage(p);
              }
            }}
            getAnnotations={(page: string) => {
              return props.getAnnotations(page);
            }}
            onAnnotationChange={(page: string, annotation?: string) => props.onAnnotationChange?.(page, annotation)}
            onLinkClicked={Linking.openURL}
            deviceType={DeviceType.web}
            onPdfPageSourceRequested={props.onPdfPageSourceRequested}
            onFluidTextSourceRequested={props.onFluidTextSourceRequested}
          />}
        </>}
    </>
  );
}
export default Index;
