import { Platform } from 'react-native';
import { Book } from './book';
import { BookCategory } from './book-category';
import { BookSortType } from './enum';
import { RemoteBook } from './remote-book';
import { RemoteBookCategory } from './remote-book-category';
import { RemoteData } from './remote-data';

export class BookList {
  get books(): Book[] {
    return this._internalList;
  }
  get categories(): BookCategory[] {
    return this._internalCategory;
  }
  get onlineCategory(): BookCategory[] {
    return this._internalCategory.filter(x => !x.offline && x.totalBooks > 0);
  }
  get offlineCategory(): BookCategory[] {
    return this._internalCategory.filter(x => x.offline);
  }
  private _internalRemoteList: RemoteBook[] = [];
  private _internalRemoteCategory: RemoteBookCategory[] = [];
  private _internalList: Book[] = [];
  private _internalCategory: BookCategory[] = [];

  constructor() {
  }

  public async setRemoteData(remoteData?: RemoteData) {
    if (remoteData) {
      this._internalRemoteList = remoteData.books;
      this._internalRemoteCategory = remoteData.categories;
    }

    // elabora la lista e verifica i file locali
    for (const b of this._internalRemoteList) {
      let isLocal = false;

      if (Platform.OS != 'web') {
        const RNFSR = require('../Services/rnfs-factory');
        const RNFS = RNFSR.RNFSFactory.getRNFS();

        const directory = `${RNFSR.RNFSFactory.getDataPath()}/${b?.identifier!}`;
        isLocal = await RNFS.exists(directory) && await RNFS.exists(`${directory}/check.txt`);
      }

      this._internalList.push({
        ...b,
        isLocal: isLocal,
        coverImageData: b.bookImage,
        author: b.originalAuthors,
        categoryId: b.category,
        matter: b.matter,
        unlockDate: b.unlockDate
      });
    }
    this._internalRemoteCategory.map(b => {
      this._internalCategory.push({
        ...b,
        offline: false,
      });
    });
    this._internalCategory.push({
      id: 'offline',
      name: 'Disponibili offline',
      totalBooks: 0,
      offline: true
    });
  }

  public setBookIsLocal(isbn: string, isLocal: boolean) {
    const book = this._internalList.find(a => a.identifier === isbn);
    if (!book) {
      throw new Error(`Book ${isbn} not exist.`);
    }

    book.isLocal = isLocal;

    //print isLocal of the book
    this._internalList.find(a => a.identifier === isbn)?.isLocal;
  }

  public getSerializedRemoteData(): string {
    const rData: RemoteData = {
      books: this._internalRemoteList,
      categories: this._internalRemoteCategory,
    };
    return JSON.stringify(rData);
  }

  public getBooksFromCategoryId(categoryId: string, maxItems: number = 999): Book[] {
    const c = this._internalCategory?.find(a => a.id === categoryId);
    if (!c) {
      throw new Error(`CategoryId ${categoryId} not exist.`);
    }

    if (c.offline)
      return this._internalList.filter(a => a.isLocal).splice(0, maxItems);

    return this._internalList.filter(a => a.categoryId === categoryId).splice(0, maxItems);
  }

  public getBookByIsbn(identifier: string): Book {
    const book = this._internalList?.find(a => a.identifier === identifier);
    if (!book) {
      throw new Error(`CategoryId ${identifier} not exist.`);
    }

    return book;
  }

  sortBooks(sortType: BookSortType): void {
    switch (sortType) {
      case BookSortType.TITLE:
        this._internalList = this._internalList.sort((a, b) => (a.title < b.title ? -1 : 1));
        break;
      case BookSortType.MATTER:
        this._internalList = this._internalList.sort((a, b) => (a.matter < b.matter ? -1 : 1));
        break;
      case BookSortType.UNLOCK_DATE:
        this._internalList = this._internalList.sort((a, b) => (a.unlockDate < b.unlockDate ? -1 : 1));
        break;
      case BookSortType.AUTHOR:
        this._internalList = this._internalList.sort((a, b) => (a.author < b.author ? -1 : 1));
        break;
    }
  }
}
