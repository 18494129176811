import { useState } from "react";
import { Linking, StyleSheet, TextProps, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { BookStrip } from "../../Models/remote-book";
import DbButton from "../db-button";
import DbRenderHtml from "../db-render-html";
import DbText from "../db-text";

export interface BookStripItemProps extends TextProps {
    item: BookStrip
}
function BookStripItem(props: BookStripItemProps) {
    const [showMaterials, setShowMaterials] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    return (
        <>
            <DbButton outline={!showMaterials} onPress={() => setShowMaterials(!showMaterials)}>
                <View style={styles.accordionButton}>
                    <View style={{width: '100%'}}>
                        <DbRenderHtml style={{width: '100%'}} contentWidth={width} html={props.item.title} />
                    </View>
                    <DbText style={styles.accordionButtonText}>{'>'}</DbText>
                </View>
            </DbButton>
            {
                showMaterials &&
                <View style={styles.materialContainer}>
                    {
                        props.item.materials?.map((m, i) => {
                            if (m.link)
                                return <TouchableOpacity key={i} style={styles.materialButton} onPress={() => m.link && Linking.openURL(m.link)}>
                                    <DbRenderHtml contentWidth={width} html={m.title} />
                                </TouchableOpacity>
                            else if (m.html)
                                return <DbRenderHtml key={i} contentWidth={width} html={m.html} />

                            return <></>;
                        })
                    }
                </View>
            }
        </>
    );
}

const styles = StyleSheet.create({
    accordionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 15
    },
    accordionButtonText: {
        textTransform: 'uppercase',
        fontSize: 16,
        fontWeight: 'bold'
    },
    materialContainer: {
        marginTop: -18,
        padding: 20,
        gap: 16,
        borderWidth: 2,
        borderColor: '#cc0000',
    },
    materialButton: {
        padding: 10,
    }
});

export default BookStripItem;
