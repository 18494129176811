import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useState } from 'react';
import {Image, Platform, ScrollView, StyleSheet, useWindowDimensions, View} from 'react-native';
import { SafeAreaView } from "react-native-safe-area-context";
import { RootStackParamList } from '../App';
import BookStripItem from '../Components/book-list/bookStripItem';
import DbButton from '../Components/db-button';
import DbText from '../Components/db-text';
import { WebErrorPage } from '../Components/web-error-page.web';
import { Book } from '../Models/book';
import { AppContext } from '../Providers/AppContext';
import BaseScreen from './BaseScreen';
import {useIsFocused} from "@react-navigation/native";

type ViewerProps = NativeStackScreenProps<RootStackParamList, 'BookDetail'>;

function BookDetailScreen({ route, navigation }: ViewerProps) {
    const [book, setBook] = useState<Book>();
    const [bookIsbn, setBookIsbn] = useState<string>();

    const [showProfessorStrips, setShowProfessorStrips] = useState<boolean>(false);
    const [showStudentStrips, setShowStudenttrips] = useState<boolean>(true);
    const appContext = React.useContext(AppContext);

    const [errorOnLoading, setErrorOnLoading] = useState<'generic' | '404'>();
    const isFocused = useIsFocused();

    const dimensions = useWindowDimensions();

    const styles = StyleSheet.create({
        container: {
            padding: 10,
            width: '100%',
            minWidth: dimensions.width < 500 ? '100%' : 780,
            maxWidth: dimensions.width < 500 ? '100%' : 780,
            marginHorizontal: 'auto',
            marginVertical: 10,
            //shadowColor: '#00000029',
            //shadowRadius: 10
        },
        containerBase: {
            marginBottom: 15,
            flexDirection: 'row'
        },
        coverImage: {
            width: 120,
            height: 200,
            marginRight: 20,
        },
        containerInfo: {
            flexDirection: 'column',
            flexShrink: 1
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold'
        },
        subTitle: {
            marginTop: 5,
            fontSize: 15
        },
        description: {
            fontSize: 16
        },
        sectionTitle: {
            fontSize: 20,
            color: '#cc0000',
            marginBottom: 5,
            marginTop: 15,
            fontWeight: 'bold'
        },
        action: {
            alignContent: 'space-between'
        },
    });

    useEffect(() => {

        if(isFocused) {

            try {
                const {bookId} = route.params!;
                setBookIsbn(bookId);

                const book = appContext.booksData.getBookByIsbn(bookId);
                setBook(book);
                setShowProfessorStrips(showProfessorStrips);
                setShowStudenttrips(!showProfessorStrips && book.studentStrips.length > 0);
                navigation.setOptions({title: book.title});

            } catch (e) {
                setErrorOnLoading('404')
                return;
            }
        } else {
            setBook(undefined);
            setErrorOnLoading(undefined);
            setShowProfessorStrips(false);
            setShowStudenttrips(false);
        }
    }, [isFocused]);

    const getPdfButtonLabel = useCallback(() => {
        let label = "LEGGI";
        if (book?.pdfBookType == 'plus')
            label += " LibroPLUS ONLINE";
        else
            label += " PDF ONLINE";

        return label;
    }, [book]);

    return (
        <>
            {(book) &&
                <BaseScreen navigation={navigation}>
                    <SafeAreaView>
                        <ScrollView contentContainerStyle={styles.container}>
                            <View style={styles.containerBase}>
                                {book.coverImageData && <Image
                                    resizeMode={'contain'}
                                    style={styles.coverImage}
                                    source={{
                                        uri: book.coverImageData
                                    }} />}
                                <View style={styles.containerInfo}>
                                    <DbText style={styles.subTitle}>{book.author}</DbText>
                                    <DbText style={styles.title}>{book.title}</DbText>
                                </View>
                            </View>
                            <View style={styles.containerBase}>
                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: 10, borderRightWidth: 1, borderRightColor: '#ddd' }}>
                                    <DbText style={{ fontWeight: '600' }}>{'ISBN'}</DbText>
                                    <DbText>{book.identifier}</DbText>
                                </View>
                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: 10, borderRightWidth: 1, borderRightColor: '#ddd' }}>
                                    <DbText style={{ fontWeight: '600' }}>{'Pagine'}</DbText>
                                    <DbText>{book.totalPagesToDisplay ?? '0'}</DbText>
                                </View>
                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start', padding: 10 }}>
                                    <DbText style={{ fontWeight: '600', fontSize: 14, marginBottom: 4 }}>{'Tipo volume'}</DbText>
                                    {(book.pdfBookType === 'plus') &&
                                        <DbText style={{fontSize: (Platform.OS === 'ios' || Platform.OS === 'android') ? 10 : 14}}>{'LibroPLUS'}</DbText>
                                    }
                                    {(book.pdfBookType === 'standard') &&
                                        <DbText style={{fontSize: (Platform.OS === 'ios' || Platform.OS === 'android') ? 10 : 14}}>{'PDF'}</DbText>
                                    }
                                    {(book.existEpub) &&
                                        <DbText style={{fontSize: (Platform.OS === 'ios' || Platform.OS === 'android') ? 10 : 14}}>{'EPUB'}</DbText>
                                    }
                                </View>
                            </View>
                            <View style={{ marginTop: 20 }}>
                                <View>
                                    {
                                        book.exist &&
                                        <View style={[styles.containerBase, styles.action]}>
                                            {book.existPdf &&
                                                <View style={{ flex: 1 }}>
                                                    <DbButton onPress={() => navigation.navigate('Viewer', { 'bookId': book.identifier, 'openPdf': true } as any)} title={getPdfButtonLabel()} disabled={!book.exist} />
                                                </View>
                                            }
                                            {book.existEpub &&
                                                <View style={{ flex: 1, marginLeft: 20 }}>
                                                    <DbButton onPress={() => navigation.navigate('Viewer', { 'bookId': book.identifier, 'openPdf': false } as any)} title={'LEGGI EPUB ONLINE'} disabled={!book.exist} />
                                                </View>
                                            }
                                        </View>
                                    }
                                    {
                                        !book.exist &&
                                        <View style={{ alignItems: 'center', width: '100%', backgroundColor: '#cc000099' }}>
                                            <DbText style={{ fontSize: 20, margin: 10, color: '#fff' }}>
                                                LIBRO NON DISPONIBILE
                                            </DbText>
                                        </View>
                                    }
                                    {
                                        book.description && <View style={styles.containerInfo}>
                                            <DbText style={styles.sectionTitle}>{'Descrizione libro'}</DbText>
                                            <DbText style={styles.description}>
                                                <div dangerouslySetInnerHTML={{ __html: book.description }}></div>
                                            </DbText>
                                        </View>
                                    }
                                    {(book.professorStrips.length > 0 || book.studentStrips.length > 0) &&
                                        <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                            <DbText style={styles.sectionTitle}>{'Contenuti digitali'}</DbText>
                                            <View style={{ display: 'flex', flexDirection: 'row', gap: 10, paddingVertical: 5 }}>
                                                {book.studentStrips.length > 0 && <View style={{ flex: 0.5 }}>
                                                    <DbButton tab={true} outline={showStudentStrips} onPress={() => { setShowProfessorStrips(false); setShowStudenttrips(true); }} title={'STUDENTE'} />
                                                </View>}
                                                {book.professorStrips.length > 0 && <View style={{ flex: 0.5 }}>
                                                    <DbButton tab={true} outline={showProfessorStrips} onPress={() => { setShowProfessorStrips(true); setShowStudenttrips(false); }} title={'DOCENTE'} />
                                                </View>}
                                            </View>
                                            <View>
                                                {showProfessorStrips && <View style={{ ...styles.containerInfo, rowGap: 15 }}>
                                                    {
                                                        book.professorStrips.map((s, i) => <BookStripItem key={i} item={s} />)
                                                    }
                                                </View>}
                                                {showStudentStrips && <View style={{ ...styles.containerInfo, rowGap: 15 }}>
                                                    {
                                                        book.studentStrips.map((s, i) => <BookStripItem key={i} item={s} />)
                                                    }
                                                </View>}
                                            </View>
                                        </View>
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    </SafeAreaView>
                </BaseScreen>
            }

            {errorOnLoading &&
                <WebErrorPage
                    navigation={navigation}
                    returnTo={'Home'}
                    message={`Il libro con ISBN ${bookIsbn} non esiste`}
                    buttonLabel={'Torna alla Home'}
                />
            }
        </>
    )
}

export default BookDetailScreen;
